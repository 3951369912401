import * as React from 'react';
import { useCallback } from 'react';
import Button from 'veho-ui/lib/Components/Button';
import { IProductLabels } from 'components/Product/Search';
import { useMutation } from 'redux-query-react';
import Toaster from 'veho-ui/lib/Components/Toaster';
import { IProductResponse } from 'components/Product/Results';
import addToWishlistRequest from 'components/Product/requests/addToWishlistRequest';

interface IProps {
    labels: IProductLabels;
    product: IProductResponse;
}

const AddWishlist: React.FunctionComponent<IProps> = (props) => {
    const { labels, product } = props;

    const [{}, addToWishlistQuery] = useMutation((productItem: IProductResponse) => addToWishlistRequest(productItem));

    const addToWishlist = useCallback(
        async (product) => {
            const response = await addToWishlistQuery(product);

            Toaster.addToast({
                intent: response.status === 200 ? 'success' : 'danger',
                text: response.body.message,
                asHtml: true,
            });
        },
        [product],
    );
    return (
        <Button
            intent="discrete"
            title={labels.toWishlist}
            onClick={(e) => {
                e.preventDefault();
                addToWishlist(product);
            }}
        />
    );
};

export default AddWishlist;
