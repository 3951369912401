import * as React from 'react';
import { ICartItem } from '../../../interfaces/checkout/cart/cartType';
import { IOptionItem } from '../../../interfaces/checkout/cart/IOptionItem';
import { sum } from '../../../helpers/cart/sum';
import * as i18next from 'i18next';

export const itemDiscountPercent = (item: ICartItem, t: i18next.TFunction): React.ReactNode => {
    const optionItems: IOptionItem = item.options ? JSON.parse(item.options) : {};
    if (optionItems.custom_base_price) {
        const productPrice = item.price_incl_tax;
        const fullPrice = optionItems.custom_base_price.value || '0';
        const productCustomerPrice = parseFloat(fullPrice);

        if (!optionItems.hide_discount?.value && productPrice < productCustomerPrice) {
            const discountPercent = Math.round(100 - (((productPrice / productCustomerPrice) / 100) * 10000));
            return (
                <span key={`${item.item_id}_rxBasePrice`}>
                    <>
                        {t('checkout.Product full price', {amount: sum(optionItems.custom_base_price.value || '0')})}
                        {optionItems.shopping_flow?.value !== 'rx_module' && <span className={'discount'}> -{discountPercent}%</span>}
                        <br/>
                    </>
                </span>
            );
        }
    }
    return null;
};
